import { ValueEditor, ValueEditorProps } from 'react-querybuilder'
import DateRangeValueEditor from './DateRangeValueEditor'
import TerritoryValueEditor from './TerritoryValueEditor'
import GroupValueEditor from './GroupValueEditor'
import GenericVirtualizedAutoComplete from './GenericVirtualizedAutoComplete'

const CustomValueEditor = (props: ValueEditorProps) => {
  if (!props.fieldData.datatype) {
    return <ValueEditor {...props} />
  }

  if (props.fieldData.datatype === 'dateRange') {
    return <DateRangeValueEditor {...props} />
  }

  if (props.fieldData.datatype.startsWith('tag') === true) {
    const tagCategoryId = props.fieldData.datatype.replace('tag', '')
    const filter = { tag_category_id: tagCategoryId }
    return (
      <GenericVirtualizedAutoComplete
        handleOnChange={props.handleOnChange}
        value={props.value}
        dpFetchValue="tags"
        label={props?.fieldData?.label}
        filter={filter}
      />
    )
  }

  if (props.fieldData.datatype === 'vendor') {
    return (
      <GenericVirtualizedAutoComplete
        handleOnChange={props.handleOnChange}
        value={props.value}
        dpFetchValue="vendors"
        label="Vendors"
      />
    )
  }

  if (props.fieldData.datatype === 'group') {
    return <GroupValueEditor {...props} />
  }

  if (props.fieldData.datatype === 'territory') {
    return <TerritoryValueEditor {...props} />
  }

  return <ValueEditor {...props} />
}

export default CustomValueEditor
